import React, { useState } from 'react';
import './App.css';

function App() {
  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (index) => {
    setActiveCard(prev => prev === index ? null : index);
  };

  return (
    <div className="app">
      <div className="cards-container">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className={`card ${activeCard === index ? 'expanded' : (activeCard === null ? 'default' : 'top')}`}
            style={{ '--index': index }} // Dynamically assign index for CSS calculations
            onClick={() => handleCardClick(index)}
          >
            {activeCard === index ? (
              <div className="card-content">Content of Card {index + 1}</div>
            ) : (
              <div className="card-title">Card {index + 1}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
