// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow: hidden;
}

.cards-container {
  display: flex;
  width: 100%; /* Full width to manage space for both states */
  height: 100%;
  position: relative;
  transition: all 1s ease;
}

.card {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  cursor: pointer;
  transition: all 1s ease;
  position: absolute; /* All cards are absolutely positioned for better control */
}

.default {
  width: 20%; /* Standard width */
  height: 60%;
  top: 20%; /* Center vertically when default */
  left: calc(10% + 22.5% * var(--index)); /* Dynamically calculate left position based on index */
}

.top {
  width: 10%; /* Smaller width when unclicked and at the top */
  height: 10%;
  top: 2%; /* Close to the top */
  left: calc(30% + 13.3% * var(--index)); /* Evenly distribute at the top */
}

.expanded {
  width: 75%;
  height: 60%;
  top: 20%;
  left: 12.5%; /* Centered horizontally */
}

.card-content, .card-title {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW,EAAE,+CAA+C;EAC5D,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,wCAAwC;EACxC,mCAA2B;UAA3B,2BAA2B;EAC3B,eAAe;EACf,uBAAuB;EACvB,kBAAkB,EAAE,2DAA2D;AACjF;;AAEA;EACE,UAAU,EAAE,mBAAmB;EAC/B,WAAW;EACX,QAAQ,EAAE,mCAAmC;EAC7C,sCAAsC,EAAE,uDAAuD;AACjG;;AAEA;EACE,UAAU,EAAE,gDAAgD;EAC5D,WAAW;EACX,OAAO,EAAE,qBAAqB;EAC9B,sCAAsC,EAAE,iCAAiC;AAC3E;;AAEA;EACE,UAAU;EACV,WAAW;EACX,QAAQ;EACR,WAAW,EAAE,0BAA0B;AACzC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".app {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  overflow: hidden;\n}\n\n.cards-container {\n  display: flex;\n  width: 100%; /* Full width to manage space for both states */\n  height: 100%;\n  position: relative;\n  transition: all 1s ease;\n}\n\n.card {\n  background-color: rgba(255, 255, 255, 0.5);\n  border: 1px solid rgba(255, 255, 255, 0.8);\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(10px);\n  cursor: pointer;\n  transition: all 1s ease;\n  position: absolute; /* All cards are absolutely positioned for better control */\n}\n\n.default {\n  width: 20%; /* Standard width */\n  height: 60%;\n  top: 20%; /* Center vertically when default */\n  left: calc(10% + 22.5% * var(--index)); /* Dynamically calculate left position based on index */\n}\n\n.top {\n  width: 10%; /* Smaller width when unclicked and at the top */\n  height: 10%;\n  top: 2%; /* Close to the top */\n  left: calc(30% + 13.3% * var(--index)); /* Evenly distribute at the top */\n}\n\n.expanded {\n  width: 75%;\n  height: 60%;\n  top: 20%;\n  left: 12.5%; /* Centered horizontally */\n}\n\n.card-content, .card-title {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
